import React from "react";
import LayoutWrapper from "../../component/LayoutWrapper/Index";
import BlogsPosts from "../../component/Blog/BlogPost/index";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ShareLinks from "../../component/Blog/ShareLinks";
import AuthorAvatar from "../../component/Blog/AuthorAvatar";
import CommonParagraph from "../../component/Blog/CommonParagraph";
import Heading from "../../component/Blog/Heading";
import BlogLink from "../../component/Blog/BlogLink";
import { blogList } from "../../data/blogs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ContactSlideoutLink from "../../component/Blog/ContactSlideoutLink";

const BlogPost = () => {
  const blogData = blogList.filter(
    (blog) => blog.url == "/virtual-tours-sell-san-diego-home-faster"
  );

  return (
    <LayoutWrapper
      title={blogData[0].title}
      desc={blogData[0].metaDescription}
      headerLogo="../../images/Selby_logo_v2.svg"
      headerId="header_v2"
    >
      <div className="pt-[130px] pb-[80px] md:pt-[170px] md:pb-[70px] sm:pt-[30px] 2xs_extar:pb-[50px]">
        <div className="container">
          <div className="max-w-[800px] w-full mx-[auto]">
            <h1 className="text-d-3xl text-[#020101] font-IbarraRealNova font-[600] mb-[32px] sm:text-d-xl sm:mb-[]">
              {blogData[0].title}
            </h1>
          </div>
          <div className="flex justify-between items-center max-w-[800px] w-full mx-[auto] mb-[40px] 2xs_extar:flex-col  2xs_extar:justify-start 2xs_extar:items-start 2xs_extar:gap-[20px]">
            <div className="flex gap-[14px]">
              <AuthorAvatar />
              <div className="">
                <a
                  href={blogData[0].authorUrl && blogData[0].authorUrl}
                  className="font-Quicksand text-[#1A1A1A] text-d-2lg font-[600] mb-[4px]"
                >
                  By {blogData[0].author}
                </a>
                <p className="font-Quicksand text-d-2lg font-[400] text-[#666666]">
                  {blogData[0].date}
                </p>
              </div>
            </div>
            <div className="flex gap-[24px]">
              <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
            </div>
          </div>

          <div className="">
            <StaticImage
              src="../../images/The Selby Team_How Virtual Tours Can Help You Sell Your San Diego Home Faster.jpg"
              alt="blogpost"
            />
            <div className="max-w-[800px] w-full mx-[auto] py-[80px] 2xs_extar:py-[30px]">
              <Heading type="h1">
                How Virtual Tours Can Help You Sell Your San Diego Home Faster
              </Heading>
              <CommonParagraph>
                It's no secret that the real estate market is competitive for buyers and sellers
                alike. If you're a San Diego homeowner ready to sell your property and start the
                next chapter of your life, standing out is more crucial than ever. So how can you
                quickly grab the attention of buyers and spark interest? Virtual tours.
              </CommonParagraph>
              <CommonParagraph>
                Here's how these immersive experiences can enhance your listing and speed up the
                sales process!
              </CommonParagraph>
              <Heading type="h2">Enhances Your Listing</Heading>
              <CommonParagraph>
                When you’re ready to{" "}
                <BlogLink
                  url="https://selbysellssd.com/sell-your-san-diego-home"
                  text="sell your house"
                />
                , a virtual tour can enhance your listing by showcasing the unique selling points of
                your home that a static image just can't do. By allowing potential buyers to walk
                through your property virtually, it's easier to grab and hold their attention right
                from the start.
              </CommonParagraph>
              <CommonParagraph>
                A virtual tour helps those searching for a home understand its flow, layout and
                features, all before connecting with your real estate agent. And when they do reach
                out, it's likely that they already have a better sense of what makes your home
                special.
              </CommonParagraph>
              <CommonParagraph>
                From zooming in on details to taking in the view of your beautifully landscaped
                backyard, the enhanced presentation of your home can make it more appealing and
                memorable, encouraging buyers to make an offer.
              </CommonParagraph>
              <Heading type="h2">Boosts Searchability</Heading>
              <CommonParagraph>
                One of the best ways to get your property in front of potential buyers and quickly
                close the deal is to leverage search engine optimization (SEO). Virtual tours are
                highly engaging pieces of content that improve your home's searchability. The more
                time shoppers spend on your property's page viewing a virtual tour, the more likely
                it is that search engines will take notice.
              </CommonParagraph>
              <CommonParagraph>
                That could potentially result in your home's listing ranking higher in search
                results. By boosting your house's visibility, a virtual tour can help you attract
                more serious buyers.
              </CommonParagraph>
              <Heading type="h2">Reduces In-Person Showings</Heading>
              <CommonParagraph>
                Virtual tours mean potential buyers can get a thorough look at your home from the
                comfort of their current one. That not only cuts down on the amount of in-person
                showings to coordinate, but it can also mean that those who do want to come take a
                look are serious about your house.
              </CommonParagraph>
              <CommonParagraph>
                What does this mean for sellers? Less time{" "}
                <BlogLink
                  url="https://selbysellssd.com/real-estate-marketing-services"
                  text="preparing your home for viewers"
                />{" "}
                and dealing with no-shows, as well as reduced wear and tear on your house from
                people going in and out. Those two perks alone can make the selling process that
                much more streamlined and efficient.
              </CommonParagraph>
              <Heading type="h2">Casts a Wider Net</Heading>
              <CommonParagraph>
                Southern California, particularly the San Diego area, is a desirable location for
                buyers across the country. But if they don't live locally, it's hard for those
                interested in your home to see it in person; virtual tours of homes for sale are the
                perfect solution.
              </CommonParagraph>
              <CommonParagraph>
                They allow more out-of-town shoppers to explore your home from wherever they are,
                broadening your pool of potential buyers. That move alone could not only speed up
                your selling process but may also start a bidding war, fetching you{" "}
                <BlogLink
                  url="https://selbysellssd.com/how-to-get-the-best-price-when-selling-your-house-in-san-diego"
                  text="top-dollar for
                your property"
                />
                .
              </CommonParagraph>
              <Heading type="h2">Speeds Up the Decision-Making Process</Heading>
              <CommonParagraph>
                One of the biggest advantages of a virtual tour is that it can accelerate a buyer's
                decision-making process. Instead of multiple in-person showings and back-and-forth
                questions, buyers can view the tour as often as they'd like, thoroughly explore, and
                feel confident about making an offer. A faster decision-making process for a buyer
                means a faster closing for you.
              </CommonParagraph>
              <Heading type="h2">Sell Your Home Fast With The Selby Team</Heading>
              <CommonParagraph>
                Looking for real estate professionals who will not only secure the competitive price
                you deserve for your house but do so quickly? Team up with The Selby Team. We know
                how to attract buyer attention, and our streamlined closing process saves time and
                hassle so you can move on to bigger, better things.{" "}
                <ContactSlideoutLink text="Connect with us today" />!
              </CommonParagraph>
            </div>
            <div className="">
              <div className="flex justify-center gap-[24px]">
                <ShareLinks url={`https://selbysellssd.com${blogData[0].url}`} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BlogPost;
